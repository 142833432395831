import * as React from "react"
import styled from "styled-components"

import { useQueryParam, StringParam } from "use-query-params"

import Title from "components/Title"
import phoneImage from "assets/images/phone-app.png"
import shapePink from "assets/images/shape-green.svg"

const ActiverenPage = () => {
  const [queryParam] = useQueryParam("qr", StringParam)
  const [qrCodeUrl, setQRCodeUrl] = React.useState("")
  React.useEffect(() => {
    if (window._env_) {
      const signature = window._env_.AZURE_STORAGE_PARAM
      setQRCodeUrl(`${queryParam}${signature}`)
    }

    setQRCodeUrl(
      `${queryParam}?sv=2018-03-28&ss=b&srt=sco&sp=rla&se=2030-02-12T17:39:24Z&st=2019-02-12T09:39:24Z&spr=https&sig=B32zW6LfihxgbROkgYl9Em8Y2HjbfC18ePJGKDLKtco%3D`
    )
  }, [])

  return (
    <>
      <Title bold marginBottom="10">
        Meedoen met Teams?
      </Title>
      <Title>Scan de QR-code om Teams te activeren.</Title>

      <Container>
        <InstructionListWrapper>
          <Instruction>
            <IconWrapper count="1">
              <img src={shapePink} alt="1" />
            </IconWrapper>
            <TextWrapper>
              <Title>Download App</Title>
              <Subtitle>
                In de{" "}
                <Link href="https://apps.apple.com/us/app/id1440467995">
                  App Store
                </Link>{" "}
                of{" "}
                <Link href="https://play.google.com/store/apps/details?id=com.actify">
                  Play Store
                </Link>
              </Subtitle>
            </TextWrapper>
          </Instruction>
          <Instruction>
            <IconWrapper count="2">
              <img src={shapePink} alt="2" />
            </IconWrapper>
            <TextWrapper>
              <Title>Registreer</Title>
              <Subtitle>Tik op Start en ga verder met de registratie</Subtitle>
            </TextWrapper>
          </Instruction>
          <Instruction>
            <IconWrapper count="3">
              <img src={shapePink} alt="3" />
            </IconWrapper>
            <TextWrapper>
              <Title>Teams</Title>
              <Subtitle>Selecteer Actify Teams en scan de QR code</Subtitle>
            </TextWrapper>
          </Instruction>
          <QRCodeWrapper>
            <QRCode src={qrCodeUrl} alt="" />
          </QRCodeWrapper>
        </InstructionListWrapper>
        <ImageWrapper>
          <img src={phoneImage} alt="Samen Fitter" />
        </ImageWrapper>
      </Container>
    </>
  )
}

const QRCode = styled.img`
  width: 275px;
  height: 275px;
`

const QRCodeWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const Link = styled.a`
  color: black;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: ${({ theme }) => theme.spacer.medium};
`

const Subtitle = styled.div`
  margin-top: 5px;
  font-size: ${({ theme }) => theme.font.size.small};
`

type IconWrapperProps = {
  count: string
}

const IconWrapper = styled.div<IconWrapperProps>`
  position: relative;
  &::before {
    content: "${({ count }) => count}";
    color: white;
    position: absolute;
    top: 9px;
    left: 21px;
    font-size: ${({ theme }) => theme.font.size.medium};
  }
`

const Instruction = styled.div`
  margin-bottom: ${({ theme }) => theme.spacer.large};
  display: flex;
`

const InstructionListWrapper = styled.div``

const ImageWrapper = styled.div`
  margin-top: 30px;
  max-width: 330px;

  img {
    max-width: 100%;
    max-height: 540px;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;

  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-evenly;
  }
`

export default ActiverenPage
