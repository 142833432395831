import styled from "styled-components"

type TitleProps = {
  bold?: boolean
  marginBottom?: string
}

const Title = styled.p<TitleProps>`
  margin: 0;
  padding: 0;
  font-size: ${({ theme }) => theme.font.size.medium};
  text-align: center;
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : 0)}px;
`

export default Title
